import React, { useState } from 'react';
import { Button } from '@antcorefinance/ui';
import { message } from 'antd';
import { Check } from 'lucide-react';

interface Props {
  tweetId: string; // The Tweet ID to retweet
  isCompleted: boolean;
  tweetText: string; // The full text of the tweet, passed in from parent
  taskId: number; // e.g., 2
  onCompleteTask: (taskId: number) => Promise<void>;
}

export const SingleTweetRetweetTask: React.FC<Props> = ({
  tweetId,
  isCompleted,
  tweetText,
  taskId,
  onCompleteTask,
}) => {
  const [didRetweet, setDidRetweet] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  // 1) Open Twitter retweet link
  const handleRetweetNow = () => {
    window.open(`https://twitter.com/intent/retweet?tweet_id=${tweetId}`, '_blank');
    setDidRetweet(true);
  };

  // 2) Verify on your backend
  const handleMarkAsCompleted = async () => {
    try {
      setIsVerifying(true);
      await onCompleteTask(taskId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsVerifying(false);
    }
  };

  // Let’s limit the text to 50 characters, plus an ellipsis:
  const truncatedText = tweetText.length > 50 ? tweetText.slice(0, 50) + '...' : tweetText;

  return (
    <div style={{}}>
      {/* Minimal “tweet snippet” instead of the full embed */}
      <p style={{ fontStyle: 'italic' }}>{truncatedText}</p>

      {/* Retweet Now button */}
      {!didRetweet && (
        <Button
          variant="outline"
          disabled={isCompleted}
          className="m-auto w-full !rounded-md bg-[#171717] !py-4"
          onClick={handleRetweetNow}
          style={{ marginTop: '0.5rem' }}
        >
          {isCompleted ? 'Task Completed' : 'Like and Retweet Now'}
        </Button>
      )}

      {/* Mark as Completed button (shown only after user clicks "Retweet Now") */}
      {didRetweet && (
        <Button
          variant="antc"
          disabled={isCompleted}
          className="m-auto w-full !rounded-md !py-4"
          // className="m-auto mt-4 w-full max-w-sm rounded-md !bg-[#077d1e] py-2 text-white"
          loading={isVerifying}
          onClick={handleMarkAsCompleted}
          style={{ marginTop: '0.5rem' }}
        >
          {isVerifying ? (
            'Verifying...'
          ) : (
            <>
              <Check />
              {isCompleted ? 'Task Completed' : 'Mark as completed'}
            </>
          )}
        </Button>
      )}
    </div>
  );
};
