'use client';

import ProfileCard from '@/components/profileCard';
import { Navigation } from '@antcorefinance/ui';
import React, { FC } from 'react';
import { UserProfile } from 'ui/common/user-profile/user-profile';

export const Header: FC = () => {
  return (
    <Navigation
      showOnramper={false}
      // middleElement={<ProfileCard />}
      leftElements={['Campaign']}
      rightElement={<UserProfile />}
    />
  );
};
