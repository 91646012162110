import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/var/www/websites/realportal/apps/aptos/app/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/var/www/websites/realportal/apps/aptos/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/websites/realportal/apps/aptos/components/ReferralUrlListener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/websites/realportal/apps/aptos/lib/NextAuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/websites/realportal/apps/aptos/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["toast","dismiss"] */ "/var/www/websites/realportal/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/websites/realportal/packages/notifications/dist/components/toast/toast-container.js");
;
import(/* webpackMode: "eager" */ "/var/www/websites/realportal/packages/notifications/dist/components/toast/toast-content.js");
;
import(/* webpackMode: "eager" */ "/var/www/websites/realportal/packages/notifications/dist/hooks/useNotifications.js");
;
import(/* webpackMode: "eager", webpackExports: ["LinkExternal"] */ "/var/www/websites/realportal/packages/ui/dist/components/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/var/www/websites/realportal/packages/ui/dist/components/scripts.js");
;
import(/* webpackMode: "eager" */ "/var/www/websites/realportal/packages/ui/index.css");
