import { IFO_ADDRESS } from '@/lib/portal/get-nft-payload';
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipPrimitive,
  TooltipProvider,
  TooltipTrigger,
  classNames,
} from '@antcorefinance/ui';
import { AntcIcon } from '@antcorefinance/ui/icons/AntcIcon';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { message } from 'antd';
import axios from 'axios';
import { Check, CheckCircle2, FileQuestionIcon, Copy } from 'lucide-react';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  AllocateButton,
  PoolCarousel,
  ProvideLiquidtyTask,
  PuzzleButton,
  TelegramComponent,
} from './poolCarousel';
import { SingleTweetRetweetTask } from './retweetTask';

interface AntModalProps {
  walletAddress?: any;
  isOpen: boolean;
  userId?: any;
  loading: boolean;
  onClose: () => void;
  taskId: number;
  totalReferrals: number;
  totalReferralPoints: number;
  taskTitle: string;
  subTasks: { id: number; title: string; points: number }[];
  onCompleteTask: (subTaskId: number, subTaskPoints: number, password?: string) => void;
  completedSubTasks: number[];
}

const TooltipIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM13.5 16.5C13.5 16.6989 13.421 16.8897 13.2803 17.0303C13.1397 17.171 12.9489 17.25 12.75 17.25C12.3522 17.25 11.9706 17.092 11.6893 16.8107C11.408 16.5294 11.25 16.1478 11.25 15.75V12C11.0511 12 10.8603 11.921 10.7197 11.7803C10.579 11.6397 10.5 11.4489 10.5 11.25C10.5 11.0511 10.579 10.8603 10.7197 10.7197C10.8603 10.579 11.0511 10.5 11.25 10.5C11.6478 10.5 12.0294 10.658 12.3107 10.9393C12.592 11.2206 12.75 11.6022 12.75 12V15.75C12.9489 15.75 13.1397 15.829 13.2803 15.9697C13.421 16.1103 13.5 16.3011 13.5 16.5ZM10.5 7.875C10.5 7.6525 10.566 7.43499 10.6896 7.24998C10.8132 7.06498 10.9889 6.92078 11.1945 6.83564C11.4001 6.75049 11.6263 6.72821 11.8445 6.77162C12.0627 6.81502 12.2632 6.92217 12.4205 7.0795C12.5778 7.23684 12.685 7.43729 12.7284 7.65552C12.7718 7.87375 12.7495 8.09995 12.6644 8.30552C12.5792 8.51109 12.435 8.68679 12.25 8.8104C12.065 8.93402 11.8475 9 11.625 9C11.3266 9 11.0405 8.88147 10.8295 8.6705C10.6185 8.45952 10.5 8.17337 10.5 7.875Z"
      fill="#0F0F0F"
    ></path>
  </svg>
);

//
// Child component for pagination
//
interface PaginatedTweetTasksProps {
  tweetTasks: { tweetText: string; tweetId: string; taskId: number }[];
  completedSubTasks: number[];
  handleCompleteTask: (taskId: number, points: number) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginatedTweetTasks: React.FC<PaginatedTweetTasksProps> = ({
  tweetTasks,
  completedSubTasks,
  handleCompleteTask,
  currentPage,
  setCurrentPage,
}) => {
  const tasksPerPage = 4; // Number of tasks to display per page
  const totalTasks = tweetTasks.length;
  const totalPages = Math.ceil(totalTasks / tasksPerPage);

  // Calculate the range of tasks to display
  const startIndex = (currentPage - 1) * tasksPerPage;
  const endIndex = startIndex + tasksPerPage;
  const currentTasks = tweetTasks.slice(startIndex, endIndex);

  // Page navigation
  const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <div className="flex flex-col gap-6">
      {/* Render the current page's tasks */}
      {currentTasks.map((task) => (
        <SingleTweetRetweetTask
          key={task.taskId}
          tweetText={task.tweetText}
          tweetId={task.tweetId}
          taskId={task.taskId}
          isCompleted={completedSubTasks.includes(task.taskId)}
          onCompleteTask={async (taskId) => {
            await handleCompleteTask(task.taskId, 500);
          }}
        />
      ))}

      {/* Pagination controls */}
      <div className="pagination-controls flex flex-row items-center justify-center gap-4">
        <Button
          variant="outlineclose"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="!card-border rounded px-3 py-1 !text-xs"
        >
          Previous
        </Button>

        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          onClick={handleNextPage}
          variant="outlineclose"
          disabled={currentPage === totalPages}
          className="!card-border rounded px-3 py-1 !text-xs"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

//
// Main AntModal component
//
const AntModal: React.FC<AntModalProps> = ({
  walletAddress,
  isOpen,
  loading,
  userId,
  onClose,
  taskId,
  totalReferrals,
  totalReferralPoints,
  taskTitle,
  subTasks,
  onCompleteTask,
  completedSubTasks,
}) => {
  const [processingTaskId, setProcessingTaskId] = useState<number | null>(null);
  const [modalHeight, setModalHeight] = useState<string>('100vh');
  const [allocationInfo, setAllocationInfo] = useState<any>(null); // Allocation info from contract
  const [fetchError, setFetchError] = useState<string | null>(null);
  const { connected, account } = useWallet();

  // ---- NEW: Parent-level state for pagination ----
  const [tweetCurrentPage, setTweetCurrentPage] = useState(1);

  // Example tweet tasks (could come from elsewhere)
  const tweetTasks = [
    {
      tweetText:
        '🌊 26,376 out of 889,678 addresses from @SuiNetwork and @Aptos have registered for the @antcorefinance genesis distribution of $ANTC.',
      tweetId: '1882967159611285592',
      taskId: 305,
    },
    {
      tweetText:
        'We’ve surpassed 25,887 registrations out of a total 889,678 for the @antcorefinance genesis drop!',
      tweetId: '1882603772750537030',
      taskId: 301,
    },
    {
      tweetText:
        'We’re on track to build the largest community-driven multichain AMM in the Move ecosystem. Over 15,035 MMs from',
      tweetId: '1880990565153861870',
      taskId: 302,
    },
    {
      tweetText:
        'The genesis drop includes 889,678 selected addresses across both @Aptos and @SuiNetwork ecosystems.',
      tweetId: '1880197635891683723',
      taskId: 303,
    },
    {
      tweetText:
        'gm friendly reminder: 889,678 addresses that provided LP on decentralized exchanges across',
      tweetId: '1879601928700891224',
      taskId: 304,
    },
  ];

  //
  // Fetch allocation data
  //
  const fetchAllocationData = useCallback(async () => {
    if (!account?.address) return;
    const url = 'https://api.mainnet.aptoslabs.com/v1/view';
    const data = {
      arguments: [account.address],
      function: `${IFO_ADDRESS}::campaign::get_user_info`,
      type_arguments: [],
    };

    try {
      const response = await axios.post(url, data);
      const isParticipating = response.data;

      if (!isParticipating || isParticipating.length === 0) {
        setFetchError('Not participating in the campaign');
        setAllocationInfo(null);
      } else {
        setAllocationInfo(isParticipating);
        setFetchError(null);
      }
    } catch (error: any) {
      setFetchError('No Allocation Found');
      setAllocationInfo(null);
    }
  }, [account?.address]);

  useEffect(() => {
    if (isOpen && account?.address) {
      fetchAllocationData();
    }
  }, [fetchAllocationData, isOpen, account?.address]);

  //
  // Allocation info display
  //
  const renderAllocationInfo = () => {
    if (fetchError) {
      return <p className="text-red-500">{fetchError}</p>;
    }
    if (allocationInfo) {
      return (
        <div className="mb02 mb-2 flex flex-col items-center gap-2">
          <p className="font-bold text-[#2ab246]">Your Allocated Amount: {allocationInfo[0]} APT</p>
        </div>
      );
    }
    return <p className="text-gray-500">Loading allocation data...</p>;
  };

  //
  // State for subTask 5 + range input
  //
  const [selectedAmount, setSelectedAmount] = useState(0); // Default value
  const [manualInput, setManualInput] = useState(''); // manual input
  const [answerInput, setAnswerInput] = useState(''); // puzzle answer
  const [hideTemp, setHideTemp] = useState(false); // Hide overlay for subTask 5 after it's done

  //
  // Copy referral URL
  //
  const copyReferralUrl = () => {
    if (!userId) {
      message.error('ID not found, please try again');
      return;
    }
    navigator.clipboard.writeText(`${window.location.origin}/?ref_id=${userId}`);
    message.success('Copied to clipboard');
  };

  //
  // Allocation selection
  //
  const handleSelectAmount = (amount: number) => {
    setSelectedAmount(amount);
    setManualInput(''); // Clear manual input
  };

  const handleManualInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Numeric check, 0-20
    if (/^\d*$/.test(value) && Number(value) >= 0 && Number(value) <= 20) {
      setSelectedAmount(Number(value));
      setManualInput(value);
    }
  };

  //
  // Lock body scroll when modal is open
  //
  useEffect(() => {
    const updateModalHeight = () => {
      const height = `${window.innerHeight}px`;
      setModalHeight(height);
    };
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      updateModalHeight();
      window.addEventListener('resize', updateModalHeight);
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      window.removeEventListener('resize', updateModalHeight);
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  //
  // Sign in with Twitter
  //
  const handleSignIn = async () => {
    try {
      const result = await signIn('twitter');
      if (result?.error) {
        console.log('Sign in failed:', result.error);
      }
    } catch (error: any) {
      console.log('Error signing in:', error.message);
    }
  };

  //
  // Mark subtask as completed
  //
  const handleCompleteTask = async (
    subTaskId: number,
    subTaskPoints: number,
    password?: string,
  ) => {
    setProcessingTaskId(subTaskId);
    await onCompleteTask(subTaskId, subTaskPoints, password);
    setProcessingTaskId(null);
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
      <div
        className="card-border card-shadow w-full max-w-[480px] overflow-y-auto rounded-lg bg-black p-6"
        style={{ maxHeight: modalHeight }}
      >
        {taskId === 3 ? null : (
          <h2 className="mb-2 text-center text-2xl font-bold text-gray-50">
            {taskId !== 5 && taskId !== 4 ? 'Complete Task' : 'Daily Tasks'}
          </h2>
        )}

        <div className="m-auto mb-4">
          {subTasks.map((subTask, index) => {
            // Determine if previous subtask is completed
            const isPreviousTaskCompleted =
              index === 0 || completedSubTasks.includes(subTasks[index - 1].id);

            // Current subtask completion
            const isTaskCompleted = completedSubTasks.includes(subTask.id);

            return (
              <div key={subTask.id} className={`relative mb-2 mt-8`}>
                {/* Overlay for locked tasks (if previous isn't done) */}
                {!isPreviousTaskCompleted && ![5, 6, 8, 9, 10].includes(subTask.id) && (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="font-bold text-white">Complete previous tasks to unlock</div>
                  </div>
                )}

                {/* Overlay if subtask is done (and not daily tasks or special conditions) */}
                {isTaskCompleted && subTask.id === 5 && !hideTemp ? (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="flex flex-col">
                      <div className="flex flex-col gap-2">
                        <div className="my-2 flex flex-row justify-center gap-2 font-bold text-[#2ab246]">
                          <CheckCircle2 color="#2ab246" />
                          Task Completed
                        </div>
                        {renderAllocationInfo()}
                      </div>

                      {/* If user allocated less than 20, show an option to allocate more */}
                      {allocationInfo && allocationInfo[0] < 20 && (
                        <div className="flex flex-col items-center gap-2">
                          <p className="my02 mt-2 text-center font-bold text-[#2ab246]">
                            Max Allocation Remaining: {20 - allocationInfo[0]} APT
                          </p>
                          <Button
                            variant="antc"
                            onClick={() => {
                              setHideTemp(true); // Hide overlay
                              handleSelectAmount(1);
                            }}
                            className="m-auto my-2 w-[14rem] !rounded-xl px-4 py-2 !text-base"
                          >
                            Increase Allocation
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : isTaskCompleted && taskId !== 4 && !hideTemp && subTask.id !== 5 ? (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="flex flex-row gap-2 font-bold text-[#2ab246]">
                      <CheckCircle2 color="#2ab246" />
                      Task Completed
                    </div>
                  </div>
                ) : null}

                {/* Subtask-specific headings/content */}
                {subTask.id === 5 ? (
                  <AntcIcon width="80" className="m-auto mb-6 w-[120]" />
                ) : subTask.id === 6 && !hideTemp ? (
                  <p className="mb-4 text-center text-lg font-semibold text-yellow-50">
                    Coming Soon 📟
                  </p>
                ) : subTask.id === 8 ? (
                  <p className="mb-4 text-center text-lg font-semibold text-yellow-50"></p>
                ) : subTask.id === 11 ? (
                  <p className="mb-4 text-center text-lg font-semibold text-yellow-50"></p>
                ) : (
                  <p className="mb-4 text-center text-lg font-semibold text-gray-50">
                    {subTask.title}
                  </p>
                )}

                {/* 
                  Render your subtask-specific UI 
                */}
                {subTask.id === 1 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p className="text-center"> (i) </p>
                    <div className="flex items-center gap-1">
                      <Button
                        onClick={handleSignIn}
                        variant="outline"
                        className="m-auto w-full !rounded-md bg-[#171717]"
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 356 322"
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M280.335 0H334.917L215.672 136.29L355.954 321.75H246.114L160.083 209.269L61.644 321.75H7.029L134.574 175.972L0 0H112.629L190.394 102.811L280.335 0ZM261.179 289.08H291.423L96.195 30.954H63.7395L261.179 289.08Z" />
                        </svg>
                        <div className="text-sm font-medium">Login</div>
                      </Button>
                    </div>
                    <p className="text-center"> (ii) </p>
                    <div className="flex items-center gap-1">
                      <Button
                        variant="outline"
                        className="m-auto w-full !rounded-md bg-[#171717] !py-6"
                      >
                        <div className="flex items-center">
                          <Image width={40} height={40} src="/logo.png" alt="Antcore Finance" />
                        </div>
                        <a
                          className="twitter-follow-button"
                          target="_blank"
                          referrerPolicy="no-referrer"
                          href="https://x.com/intent/follow?screen_name=antcorefinance"
                          data-size="large"
                          rel="noreferrer"
                        >
                          Follow @antcorefinance
                        </a>
                      </Button>
                    </div>
                  </div>
                )}

                {subTask.id === 2 && (
                  <iframe
                    id="twitter-widget"
                    title="Twitter Tweet"
                    className="h-[290px] sm:h-[420px]"
                    width="100%"
                    style={{ colorScheme: 'normal' }}
                    src="https://platform.twitter.com/embed/Tweet.html?dnt=false&frame=true&hideCard=true&hideThread=true&id=1878069890008912020&lang=en&theme=dark&height=340"
                  />
                )}

                {subTask.id === 3 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <PoolCarousel />
                    <p> (i) Make sure you have 1 Testnet APT + gas fee.</p>
                    <p> (ii) Your wallet is connected to the Aptos Testnet.</p>
                  </div>
                )}

                {subTask.id === 4 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <ProvideLiquidtyTask />
                    <p>
                      (i)
                      <a
                        href="https://antcore.finance/swap?outputCurrency=0xc4afaf44985dfaa57d0a64b4151c3998238678c25b0bf24dae345a044cb7d058%3A%3Aantcore%3A%3AAntCoin"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 mr-2 text-blue-500 underline hover:text-blue-700"
                      >
                        Swap APT for ANTC on antcore.finance
                      </a>
                    </p>
                    <p>
                      (ii)
                      <a
                        href="https://antcore.finance/add/0x1::aptos_coin::AptosCoin/0xc4afaf44985dfaa57d0a64b4151c3998238678c25b0bf24dae345a044cb7d058::antcore::AntCoin"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 mr-2 text-blue-500 underline hover:text-blue-700"
                      >
                        Provide ANTC-APT Liquidity
                      </a>
                    </p>
                    <p>
                      (iii)
                      <a
                        href="https://antcore.finance/farms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 mr-2 text-blue-500 underline hover:text-blue-700"
                      >
                        Stake ANTC-APT LP
                      </a>
                    </p>
                  </div>
                )}

                {subTask.id === 5 && hideTemp && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p className="m-auto text-center">
                      Allocate APT and collaborate with Antcore Workers.
                    </p>
                    <div className="relative mx-auto mt-4 w-full max-w-md">
                      <input
                        autoFocus={!isTaskCompleted}
                        onChange={handleManualInput}
                        className={`h-12 w-full rounded-lg border px-4 py-2 text-lg text-white ${
                          selectedAmount || manualInput
                            ? 'border-gray-500 placeholder-[#fff]'
                            : 'placeholder-text-white border-gray-700'
                        } transition-all duration-200 focus:border-gray-500 focus:outline-none`}
                        autoCorrect="off"
                        autoCapitalize="none"
                        value={
                          [1, 3, 5, 10, 15, 25, 50, 75, 99].includes(selectedAmount)
                            ? selectedAmount
                            : manualInput
                        }
                        placeholder={selectedAmount?.toString() || 'Enter amount'}
                        spellCheck="false"
                        autoComplete="off"
                      />
                      <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform items-center gap-2">
                        <Image
                          width={20}
                          height={20}
                          src="https://tokens-antcore.vercel.app/images/aptos/0x1::aptos_coin::AptosCoin.png"
                          alt="APT Token"
                        />
                        <span className="text-white">APT</span>
                      </div>
                    </div>

                    <div className="flex w-full items-center gap-4">
                      <input
                        type="range"
                        min="1"
                        max="20"
                        step="1"
                        className="h-2 w-full cursor-pointer appearance-none rounded-lg"
                        style={{
                          background: '#1c1c1c',
                          outline: 'none',
                          WebkitAppearance: 'none',
                          borderRadius: '9999px',
                          backgroundImage: `linear-gradient(to right, #4a4a4a, #fff ${
                            ((selectedAmount || 1) / 20) * 100
                          }%, #2a2a2a 100%)`,
                        }}
                        value={selectedAmount || 1}
                        onChange={(e) => handleSelectAmount(Number(e.target.value))}
                      />
                    </div>

                    <AllocateButton
                      amount={Number(manualInput) > 0 ? Number(manualInput) : selectedAmount}
                    />
                    <p className="whitespace-nowrap">
                      (i) Make sure your wallet is connected to the <strong>APT Mainnet.</strong>
                    </p>
                    <p>(ii) Allocate a minimum of 1 and a maximum of 20 APT.</p>
                    <p>
                      Details:{' '}
                      <a
                        href="https://docs.antcore.finance/campaign"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://docs.antcore.finance/campaign
                      </a>
                    </p>
                  </div>
                )}

                {subTask.id === 6 && hideTemp && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <TelegramComponent />
                  </div>
                )}

                {subTask.id === 50 && (
                  <>
                    <p className="text-center text-sm text-gray-400">
                      Click &quot;Like and Retweet Now&quot; to open Twitter, then return and press
                      &quot;Mark as Completed.&quot;
                    </p>

                    <div className="mb-5 mt-6 flex flex-col gap-6">
                      {/* Render the paginated tweets using parent-level pagination */}
                      <PaginatedTweetTasks
                        tweetTasks={tweetTasks}
                        completedSubTasks={completedSubTasks}
                        handleCompleteTask={handleCompleteTask}
                        currentPage={tweetCurrentPage}
                        setCurrentPage={setTweetCurrentPage}
                      />
                    </div>
                  </>
                )}

                {subTask.id === 9 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <Button
                      variant="antc"
                      onClick={async () => {
                        await handleCompleteTask(subTask.id, subTask.points);
                        window.open('https://x.com/antcorefinance', '_blank');
                      }}
                      className="m-auto mt-2 !flex w-[14rem] !rounded-xl px-4 py-2 !text-base"
                      disabled={processingTaskId === subTask.id}
                    >
                      {processingTaskId === subTask.id ? (
                        'Processing...'
                      ) : (
                        <>
                          Visit Antcore on
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 356 322"
                            fill="#fff"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M280.335 0H334.917L215.672 136.29L355.954 321.75H246.114L160.083 209.269L61.644 321.75H7.029L134.574 175.972L0 0H112.629L190.394 102.811L280.335 0ZM261.179 289.08H291.423L96.195 30.954H63.7395L261.179 289.08Z" />
                          </svg>
                        </>
                      )}
                    </Button>
                  </div>
                )}

                {subTask.id === 10 && (
                  <Button
                    variant="antc"
                    onClick={async () => {
                      await handleCompleteTask(subTask.id, subTask.points);
                      window.open('https://antcore.finance', '_blank');
                    }}
                    className="m-auto mt-2 !flex w-[14rem] !rounded-xl px-4 py-2 !text-base"
                    disabled={processingTaskId === subTask.id}
                  >
                    {processingTaskId === subTask.id ? (
                      'Processing...'
                    ) : (
                      <>
                        Visit Antcore Home
                        <Image width={20} height={20} src="/logo.png" alt="Aptos Dex Antcore" />
                      </>
                    )}
                  </Button>
                )}

                {subTask.id === 11 && (
                  <div className="card-border flex h-full w-full flex-col gap-6 rounded-lg bg-gray-900 p-4 shadow-lg">
                    <h3 className="text-center text-2xl font-bold text-white">Referral Program</h3>
                    <p className="text-center text-base text-gray-300">
                      Invite your friends and earn rewards!
                    </p>
                    <div className="flex flex-col items-center gap-2">
                      {userId && (
                        <>
                          <p className="text-center text-sm text-gray-400">Your Referral ID:</p>
                          <p className="text-green-400 text-center text-xl font-semibold">
                            {userId}
                          </p>

                          <div className="mt-4 flex flex-row gap-2">
                            <Button variant="outline" onClick={copyReferralUrl}>
                              <Copy size={18} />
                              <div className="text-sm font-medium"> Copy Referral URL</div>
                            </Button>

                            <Button
                              variant="outline"
                              className="m-auto w-max !rounded-md bg-[#171717]"
                            >
                              <a
                                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                  `Join the @antcorefinance gamified testnet campaign and start earning rewards! Choose your faction, help us test the platform, and earn eANTC ⚡️ points. Use my referral link to get started: ${window.location.origin}/?ref_id=${userId}`,
                                )}`}
                                className="flex flex-row gap-2"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 356 322"
                                  fill="#fff"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M280.335 0H334.917L215.672 136.29L355.954 321.75H246.114L160.083 209.269L61.644 321.75H7.029L134.574 175.972L0 0H112.629L190.394 102.811L280.335 0ZM261.179 289.08H291.423L96.195 30.954H63.7395L261.179 289.08Z" />
                                </svg>
                                <div className="text-sm font-medium"> Share Referral Link</div>
                              </a>
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex flex-col gap-4 rounded-md bg-gray-800 p-4">
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-semibold text-white">Total Referrals:</span>
                        <span className="text-green-400 text-lg font-bold">
                          {totalReferrals || 0}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-semibold text-white">eANTC Earned:</span>
                        <span className="text-green-400 text-lg font-bold">
                          {totalReferralPoints || 0}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 text-center text-sm text-gray-400">
                      <p>
                        Earn <span className="text-green-400 font-semibold">10%</span> of points
                        from tasks completed by your referrals!
                      </p>
                    </div>
                  </div>
                )}

                {subTask.id === 97 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p className="m-auto text-center">Enter the decoded message:</p>
                    <input
                      type="text"
                      className="m-auto mt-4 w-full max-w-sm rounded-lg border bg-gray-800 px-4 py-2 text-lg text-white"
                      placeholder="Enter your answer"
                      value={manualInput}
                      onChange={(e) => setManualInput(e.target.value)}
                    />
                    <Button
                      variant="antc"
                      disabled={processingTaskId === subTask.id}
                      className="m-auto mt-4 w-full max-w-sm rounded-md !bg-[#077d1e] py-2 text-white"
                      onClick={() => {
                        handleCompleteTask(subTask.id, subTask.points, manualInput);
                      }}
                    >
                      {processingTaskId === subTask.id
                        ? 'Processing...'
                        : completedSubTasks.includes(subTask.id)
                          ? 'Completed'
                          : 'UNLOCK TASK'}
                    </Button>
                  </div>
                )}

                {subTask.id === 98 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p
                      onClick={() => {
                        const fileUrl = '/finalpuzzle.txt';
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.download = 'FinalPuzzle.txt';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                      }}
                      className="cursor-pointer text-center text-blue-500 underline hover:text-blue-700"
                    >
                      FinalPuzzle.txt
                    </p>
                  </div>
                )}

                {subTask.id === 100 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <div className="mt-4 flex flex-col gap-2 px-5 text-sm">
                      <p>🌀 Everything is not as it seems. Look closer.</p>
                      <p>🌀 The treasure is hidden, and only the sharpest eyes will find it.</p>
                      <p>
                        🌀 What you’re seeking isn’t in the parts—it’s in how they come together.
                      </p>
                    </div>
                    <input
                      type="text"
                      className="m-auto mt-2 w-full max-w-sm rounded-lg border bg-gray-800 px-4 py-2 text-lg text-white"
                      placeholder="Enter your answer"
                      value={answerInput}
                      onChange={(e) => setAnswerInput(e.target.value)}
                    />
                    <PuzzleButton answer={answerInput} />
                  </div>
                )}

                {/* "Mark as Completed" button for tasks that are not special cases */}
                {![5, 6, 9, 10, 11, 50].includes(subTask.id) && (
                  <Button
                    variant="antc"
                    onClick={() => handleCompleteTask(subTask.id, subTask.points)}
                    className="m-auto mt-6 !flex w-full !rounded-xl px-4 py-2 !text-base"
                    disabled={
                      processingTaskId === subTask.id || isTaskCompleted || !isPreviousTaskCompleted
                    }
                  >
                    {processingTaskId === subTask.id ? (
                      'Processing...'
                    ) : isTaskCompleted ? (
                      'Completed'
                    ) : (
                      <>
                        <Check />
                        Mark as completed
                      </>
                    )}
                  </Button>
                )}
              </div>
            );
          })}
        </div>

        <div className="mt-10 flex justify-center">
          <Button
            variant="outlineclose"
            onClick={() => {
              onClose();
              setHideTemp(false); // Reset the overlay if you want
            }}
            className="rounded-md px-4 py-2 text-white"
          >
            Close
          </Button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default AntModal;
