import { ClipboardController, IconButton, SkeletonText } from '@antcorefinance/ui';
import { LinkExternal } from '@antcorefinance/ui';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { Cog6ToothIcon, DocumentDuplicateIcon, LinkIcon } from '@heroicons/react/24/outline';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { formatUSD } from 'antc/format';
import { Button, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { networkNameToNetwork } from 'config/chains';
import { Aptos } from 'config/coins';
import { useNetwork } from 'lib/common/use-network';
import { useStablePrice } from 'lib/common/use-stable-price';
import React, { Dispatch, SetStateAction } from 'react';
import { ProfileView } from './user-profile';

interface Props {
  balance: number | undefined;
  setView: Dispatch<SetStateAction<ProfileView>>;
  faction: number | null;
  userPoints: number;
  id: number | null;
  referrals: number;
  referralPoints: number;
}

const factions = [
  {
    id: 1,
    name: 'Red Swarm',
    color: 'bg-red-600',
    description: 'Unite under the Red Swarm banner.',
  },
  {
    id: 2,
    name: 'Blue Nest',
    color: 'bg-blue-600',
    description: 'Organize and strategize with the Blue Nest.',
  },
  {
    id: 3,
    name: 'Purple Hive',
    color: 'bg-purple-600',
    description: 'Innovate and adapt within the Purple Hive.',
  },
];

export const DefaultView = ({
  balance,
  setView,
  faction,
  userPoints,
  id,
  referrals,
  referralPoints,
}: Props) => {
  const { account, disconnect } = useWallet();
  const { network } = useNetwork();

  let [big, portion] = (balance ? `${balance}` : '0.00').split('.');
  portion = portion ? portion.substring(0, 2) : '';

  const price = useStablePrice({ currency: Aptos[network] });

  // Find the faction name based on the factionId
  const factionName = factions.find((f) => f.id === faction)?.name || '';

  const factionColor = factions.find((f) => f.id === faction)?.color || '';

  const copyReferralUrl = () => {
    navigator.clipboard.writeText(`${window.location.origin}/?ref_id=${id}`);

    message.success({
      content: (
        <div className={`rounded-lg`}>
          <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
            <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
              <div className="flex flex-row gap-3 px-4 py-2">
                <p className="!mb-0 font-mono !text-sm text-green sm:!text-lg">
                  {' '}
                  Successfully copied
                </p>
              </div>
            </Title>
          </div>
        </div>
      ),
      className: 'ant-messagex rounded-lg', // Custom class for the message

      duration: 3, // Keeps the message visible until manually closed
      icon: <></>, // This removes the default icon
    });
  };

  return (
    <div className="flex flex-col gap-8">
      {/* <div className="flex justify-between gap-2">
        <div className="flex gap-2">
          <IconButton
            size="sm"
            icon={Cog6ToothIcon}
            onClick={() => setView(ProfileView.Settings)}
            description="Settings"
            name="Settings"
          />
          <ClipboardController hideTooltip>
            {({ setCopied, isCopied }) => (
              <IconButton
                size="sm"
                icon={DocumentDuplicateIcon}
                onClick={() => setCopied(account?.address as string)}
                description={isCopied ? "Copied!" : "Copy Address"}
                name="Copy"
              />
            )}
          </ClipboardController>

          <LinkExternal
            href={`https://explorer.aptoslabs.com/account/${
              account?.address
            }?network=${networkNameToNetwork(network)}`}
          >
            <IconButton
              size="sm"
              icon={LinkIcon}
              description="View on Explorer"
              name="View on Explorer"
            />
          </LinkExternal>

     
        </div>
      </div> */}
      <div className="flex flex-col items-center justify-center gap-2">
        <p
          className={`whitespace-nowrap rounded-md px-4 py-2 font-mono text-xl font-semibold ${factionColor}`}
        >
          {factionName} Faction
          {/* {portion && <>.{portion}</>}  */}
          {/* APT */}
        </p>
        {!price || !balance ? (
          <div className="w-12">{/* <SkeletonText fontSize="default" /> */}</div>
        ) : (
          <p className="font-medium text-slate-400">{/* {formatUSD(price * balance)} */}</p>
        )}
        {/* Display faction name and user points */}
        <div className="mt-4 text-center">
          {/* <p className="text-xl font-semibold">Faction: {factionName}</p> */}
          <p className="text-xl font-semibold">eANTC ⚡ Points: {userPoints}</p>
        </div>

        {/* <div className="mt-4 text-center">
          <p className="text-xl font-semibold">
            Total Referrals: {referrals}{' '}
            {referralPoints && <sup className="font-sm text-green">+{referralPoints} PT</sup>}
          </p>
        </div> */}
        {/* <div className="mt-4 text-center">
          <Button
            className="bg-gray-900 hover:bg-gray-800 dark:bg-white dark:hover:bg-gray-50"
            onClick={copyReferralUrl}
          >
            Click to Copy Referral URL
          </Button>
        </div> */}

        <div className="mt-4">
          <IconButton
            size="sm"
            icon={ArrowLeftOnRectangleIcon}
            onClick={() => disconnect()}
            description="Disconnect"
            name="Disconnect"
          />
        </div>
      </div>
    </div>
  );
};
