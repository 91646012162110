'use client';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

function ReferralUrlListener() {
  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    // Check if 'ref_id' exists in the URL search parameters
    const refId = searchParams.get('ref_id');

    if (refId) {
      // Store the ref_id in localStorage
      localStorage.setItem('ref_id', refId);

      // Construct a new URL without the ref_id parameter
      const params = new URLSearchParams(searchParams);
      // params.delete('ref_id');

      const newUrl = `${window.location.pathname}${
        params.toString() ? '?' + params.toString() : ''
      }`;

      // Replace the current route with the new one (without ref_id)
      // router.replace(newUrl);
    }
  }, [searchParams, router]);

  return <></>;
}

export default ReferralUrlListener;
