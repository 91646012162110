import { WalletIcons } from '@/ui/common/user-profile/connect-view/connect-view';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@antcorefinance/ui';
import { WalletName, useWallet } from '@aptos-labs/wallet-adapter-react';
import { message } from 'antd';
import Title from 'antd/es/typography/Title';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

interface Faction {
  name: string;
  points: number;
  totalUsers: number;
  id: number;
}

const factions = [
  {
    id: 1,
    name: 'Red Swarm',
    color: 'text-red-600',
    bgcolor: 'bg-red-600',
    ring: 'red',

    description: 'Unite under the Red Swarm banner.',
  },
  {
    id: 2,
    name: 'Blue Nest',
    color: 'text-blue-600',
    bgcolor: 'bg-blue-600',
    ring: 'blue',
    description: 'Organize and strategize with the Blue Nest.',
  },
  {
    id: 3,
    name: 'Purple Hive',
    color: 'text-purple-600',
    ring: 'purple',
    bgcolor: 'bg-purple-600',
    description: 'Innovate and adapt within the Purple Hive.',
  },
];

const FactionStats: React.FC = () => {
  const [factionData, setFactionData] = useState<Faction[]>(() => {
    const cachedData = localStorage.getItem('factionData');
    return cachedData
      ? JSON.parse(cachedData)
      : [
          { name: 'Red Swarm', points: 0.0, totalUsers: 0, id: 1 },
          { name: 'Blue Nest', points: 0.0, totalUsers: 0, id: 2 },
          { name: 'Purple Hive', points: 0.0, totalUsers: 0, id: 3 },
        ];
  });

  const [previousPoints, setPreviousPoints] = useState<number[]>(() => {
    const cachedData = localStorage.getItem('factionData');
    return cachedData
      ? JSON.parse(cachedData).map((faction: Faction) => faction.points)
      : [0, 0, 0];
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { connected, account, connect, wallets, signMessage } = useWallet();
  const [factionToJoin, setFactionToJoin] = useState<number | any>(null);

  const factionOrder = ['Red Swarm', 'Blue Nest', 'Purple Hive'];

  const isFetching = useRef(false); // Persistent across renders

  const getFactionBgColor = (factionId: number) => {
    const faction = factions.find((f) => f.id === factionId);
    return faction ? faction.bgcolor : 'bg-default'; // Default value if not found
  };

  useEffect(() => {
    const fetchFactionData = async () => {
      if (isFetching.current) return; // Avoid concurrent fetches
      isFetching.current = true;

      try {
        const response = await fetch('/api/faction-points');
        const data = await response.json();

        if (data.success) {
          const sortedData = data.factions.sort(
            (a: any, b: any) => factionOrder.indexOf(a.name) - factionOrder.indexOf(b.name),
          );

          // Update previous points for animation
          setPreviousPoints(factionData?.map((faction) => faction.points) || []);

          // Update faction data state
          setFactionData(sortedData);

          // Cache the fresh data
          localStorage.setItem('factionData', JSON.stringify(sortedData));

          setError(null);
        } else {
          setError(data.error || 'An error occurred while fetching data.');
        }
      } catch (err) {
        console.error('Error fetching faction data:', err);
        setError('Failed to load faction data.');
      } finally {
        isFetching.current = false;
        setLoading(false);
      }
    };

    // Fetch data once when the component mounts
    fetchFactionData();
  }, []); // Dependency array ensures the effect runs only once

  const handleJoinFaction = async (factionId: number) => {
    if (!connected || !account?.address) {
      setFactionToJoin(factionId); // Store the faction user wants to join
      return;
    }

    setLoading(true);
    const walletAddress = account.address;

    try {
      // Prepare the message payload
      const signMessagePayload = {
        address: true,
        application: true,
        chainId: true,
        message: `I'm verifying account ownership and joining the ${
          factions.find((f) => f.id === factionId)?.name
        } faction.`,
        nonce: new Date().toISOString(), // Use a unique nonce each time
      };
      const referrerId = localStorage.getItem('ref_id') ?? null;

      // Sign the message
      const signResponse = await signMessage(signMessagePayload);

      // Assuming `account.publicKey` provides the public key, add it to the signResponse
      //@ts-ignore
      signResponse.publicKey = account.publicKey;

      // Send the signed message along with the wallet address to your backend
      const response = await axios.post('/api/auth/sign', {
        walletAddress,
        referrerId,
        factionId,
        signResponse, // include the signed message response
      });

      setLoading(false);

      if (response.data.success) {
        const faction = factions.find((f) => f.id === factionId);

        message.success({
          content: (
            <div className={`${faction?.bgcolor} rounded-lg`}>
              <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
                <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
                  <div className="flex flex-row gap-3 px-4 py-2">
                    <p className="!mb-0 font-mono !text-sm sm:!text-lg">
                      {' '}
                      Successfully joined the{' '}
                    </p>
                    <p className="!mb-0 !font-mono !text-sm !font-bold sm:!text-lg">
                      {factions.find((f) => f.id === factionId)?.name} Faction
                    </p>
                  </div>
                </Title>
              </div>
            </div>
          ),
          className: 'ant-messagex rounded-lg', // Custom class for the message

          duration: 3, // Keeps the message visible until manually closed
          icon: <></>, // This removes the default icon
        });
      } else {
        const errorMessage = response.data.message || 'Failed to join the faction.';

        message.error({
          content: (
            <div className="rounded-lg bg-red-600">
              <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
                <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
                  <div className="flex flex-row gap-3 px-4 py-2">
                    <p className="!mb-0 font-mono !text-sm sm:!text-lg">{errorMessage}</p>
                  </div>
                </Title>
              </div>
            </div>
          ),
          className: 'ant-messagex rounded-lg',
          duration: 3,
          icon: <></>,
        });
      }
    } catch (error) {
      let errorMessage = 'An unknown error occurred.';

      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || error.response.data.error || errorMessage;
        } else if (error.request) {
          errorMessage = 'No response received from the server.';
        } else {
          errorMessage = error.message;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      message.error({
        content: (
          <div className="rounded-lg bg-red-600">
            <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
              <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
                <div className="flex flex-row gap-3 px-4 py-2">
                  <p className="!mb-0 font-mono !text-sm sm:!text-lg">{errorMessage}</p>
                </div>
              </Title>
            </div>
          </div>
        ),
        className: 'ant-messagex rounded-lg',
        duration: 3,
        icon: <></>,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSelectWallet = async (name: WalletName) => {
    try {
      await connect(name); // Await the connect function
      if (factionToJoin) {
        handleJoinFaction(factionToJoin); // Attempt to join the faction again after connection
        setFactionToJoin(null); // Reset the stored faction ID
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  return (
    <div className="xs:my-8 md:my-12">
      <section className="m-auto rounded-lg text-center">
        <div className="flex flex-col justify-between gap-6 sm:flex-row">
          {factionData.map((faction, index) => (
            <div
              key={index}
              className={`card-border xs:max-md:border-b xs:max-md:pb-6 rounded-xl bg-cardBlack p-4 md:basis-1/3 ${
                index !== factionData.length - 1 ? '' : ''
              }`}
            >
              <h6
                className={`fnt-body-one text-modern-dark !text-document-secondary xs:max-md:mb-2 text-sm font-medium leading-snug ${
                  index === 0 ? 'text-red' : index === 1 ? 'text-blue' : 'text-purple-600'
                }`}
              >
                {faction.name}
              </h6>
              <div className="xs:text-[21px] relative font-bold md:text-2xl">
                <CountUp
                  start={previousPoints[index]}
                  end={faction.points}
                  decimals={0}
                  duration={1}
                  separator=","
                />
              </div>
              <p className="fnt-body-one text-modern-dark !text-document-secondary xs:max-md:mb-2 text-sm font-medium leading-snug">
                Total Players: {faction.totalUsers}
              </p>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
                  <button
                    className={`${getFactionBgColor(
                      faction.id,
                    )} focus-visible:ring-ring mt-4 rounded px-4 py-2 !ring-green ring-offset-background transition-colors hover:bg-opacity-80 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`}
                    onClick={() => handleJoinFaction(faction.id)}
                    disabled={loading}
                  >
                    {connected ? `Join The ${faction.name}` : `Join The ${faction.name}`}
                  </button>

                  {/* <Button
                variant="na"
                className={`mt-4 px-4 py-2 ${faction.color} rounded-sm`}
                onClick={() => handleJoinFaction(faction.id)}
                disabled={loading}
              >
                {connected
                  ? `Join The ${faction.name}`
                  : `Join The ${faction.name}`}
              </Button> */}
                </DropdownMenuTrigger>
                {!connected && (
                  <DropdownMenuContent className="w-56">
                    <DropdownMenuGroup>
                      {wallets?.map((wallet) => {
                        const Icon = WalletIcons[wallet.name];
                        return (
                          <DropdownMenuItem
                            onClick={() => onSelectWallet(wallet.name as WalletName)}
                            key={wallet.name}
                          >
                            {Icon && <Icon className="mr-2 h-4 w-4" />}
                            {wallet.name}
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                )}
              </DropdownMenu>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FactionStats;
