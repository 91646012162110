import { ConnectButton } from '@/ui/common/user-profile/connect-button';
import { Button } from '@antcorefinance/ui';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { Tag, Typography, message } from 'antd';
import { ChevronsRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import AntModal from './antModal';
import { getUserReferralsByWallet } from './events';
import SuccessModal from './successModal';
// import { FileWarningIcon } from "lucide-react";
// import { LucideCheckCircle as CheckCircleOutlined } from "lucide-react";

//@ts-ignore
const TaskCard = ({ taskId, taskTitle, taskPoints, taskNumber, subTasks, completedSubTasks }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { connected, account } = useWallet();

  const [isModalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const { Title, Text } = Typography;

  const [totalReferrals, setTotalReferrals] = useState(0);
  const [userId, setUserId] = useState<any>();

  const [totalReferralPoints, setTotalReferralPoints] = useState(0);

  useEffect(() => {
    const fetchReferralData = async () => {
      if (account?.address) {
        try {
          setLoading(true);
          const data = await getUserReferralsByWallet(account?.address);
          setTotalReferrals(data.totalReferrals);
          setUserId(data.userId);
          console.log(data.totalReferrals);
          setTotalReferralPoints(data.totalPoints);
          setLoading(false); // Ensure loading is set to false after fetch (success or failure)
        } catch (error) {
          console.error('Error fetching referral data:', error);
        } finally {
          setLoading(false); // Ensure loading is set to false after fetch (success or failure)
        }
      }
    };

    if (account?.address) {
      fetchReferralData();
    }
  }, [account?.address]);

  const handleSuccess = (subTaskPoints: any) => {
    setEarnedPoints(subTaskPoints);
    setModalSuccessOpen(true);
  };

  // Initialize the local state with the preloaded completedSubTasks
  const [localCompletedSubTasks, setLocalCompletedSubTasks] = useState(completedSubTasks || []);

  useEffect(() => {
    // Merge preloaded completed tasks into local state when component mounts
    setLocalCompletedSubTasks(completedSubTasks || []);
  }, [completedSubTasks]);

  const handleStartQuest = async () => {
    // console.log('Button clicked');

    setIsModalOpen(true);

    if (!connected || !account?.address) {
      return <ConnectButton variant="outline" />;
    }

    // Additional logic for starting the quest...
  };

  //@ts-ignore
  const handleCompleteTask = async (subTaskId, subTaskPoints, ctfEventPassword) => {
    // setLoading(true);
    try {
      const response = await fetch('/api/complete-task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: account?.address,
          taskId: subTaskId,
          ctfEventPassword: ctfEventPassword,
        }),
      });

      const result = await response.json();
      if (result.success) {
        // Update the local state to reflect the completed task
        setLocalCompletedSubTasks((prev: any) => [...prev, subTaskId]);

        message.success({
          content: (
            <div className="text-center">
              <Title level={3} style={{ marginTop: '10px', color: '#fff' }}>
                Task completed!
              </Title>
              {/* <Text
                style={{
                  color: "#52c41a",

                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                Task successfully completed!
              </Text> */}

              <div className="mb-6 mt-4 flex min-w-[23rem] flex-row items-center justify-center gap-2">
                <Title
                  //   level={1}
                  className="!text-5xl"
                  style={{
                    color: '#52c41a',
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  +
                  <CountUp
                    start={0}
                    end={result.pointsAwarded}
                    decimals={2}
                    duration={1}
                    separator=","
                  />
                </Title>
                <Text
                  className="flex self-end !text-2xl"
                  strong
                  style={{
                    color: '#52c41a',
                    fontSize: '16px',
                  }}
                >
                  eANTC ⚡ Points
                </Text>
              </div>
            </div>
          ),
          className: 'ant-message rounded-lg', // Custom class for the message

          duration: 4, // Keeps the message visible until manually closed
          icon: <></>, // This removes the default icon
        });
      } else {
        // handleSuccess(subTaskPoints);
        message.error(result.error);
        // alert("Failed to complete task.");
      }
    } catch (error) {
      console.error('Error completing task:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (taskId === 99) {
    return (
      <div className="card-border min-h-[330px] max-w-md rounded-md bg-cardBlack p-4">
        <div className="flex h-full flex-col justify-between">
          <div className="flex h-full flex-col">
            {/* Event Header */}

            <div className="mb-4 flex items-center justify-between">
              <h3 className="text-green-400 font-tiny text-xl font-bold">CTF</h3>

              <p className="font-mono text-grey">Completed</p>

              {/* <span className="bg-green-500 flex items-center gap-2 rounded-full px-3 py-1 text-sm font-semibold text-gray-100">
                <div className="h-2.5 w-2.5 animate-pulse rounded-full bg-[#4df86e]"></div>
                LIVE
              </span> */}
            </div>

            {/* Event Description */}
            <div className="text-lg text-gray-100">
              {/* <p className="mb-2 text-sm">
                Follow the clues and solve the event task to claim your reward!
              </p> */}

              <div className="mb-4">
                {/* <div className="flex flex-row"> */}
                <p className="text-base text-gray-400">
                  Get started 👉
                  <a
                    href="https://explorer.aptoslabs.com/account/0x6b7786c83fa715f78425401736e42c7753aa9ce2fda025850fa8add1cb1c0e0b/modules/code/testnet_campaign?network=testnet"
                    target="_blank"
                    className="ml-1 text-base font-semibold text-blue-400 hover:underline"
                    rel="noreferrer"
                  >
                    Here
                  </a>{' '}
                </p>

                {/* </div> */}
                {/* <p className="text-sm text-gray-400">
                  Get started
                  <a
                    href="https://t.me/antcoreprotocol"
                    target="_blank"
                    className="font-semibold text-blue-400"
                  >
                    https://explorer.aptoslabs.com/account/0x457616f0cefc11c72a6964607e81d33b4d247b7438a83e07a0554b03dc3a38d4/modules/code/testnet_campaign?network=testnet
                  </a>{' '}
                </p> */}
              </div>

              {/* Prizes */}
              <div>
                <h4 className="mb-2 font-semibold">Event Prizes:</h4>
                <ul className="ml-1 flex flex-col gap-2">
                  <li>
                    <div className="flex flex-col gap-2 sm:flex-row">
                      <strong>🥇 1st Place: 10,000 eANTC ⚡ + $500 USD₮ </strong>
                    </div>
                  </li>
                  <li>
                    <div className="flex flex-col gap-2 sm:flex-row">
                      <strong>🥈 2nd Place: 6,600 eANTC ⚡ </strong>
                    </div>{' '}
                  </li>
                  <li>
                    <div className="flex flex-col gap-2 sm:flex-row">
                      <strong>🥉 3rd Place: 4,200 eANTC ⚡ </strong>
                    </div>{' '}
                  </li>
                  <li>
                    <div className="flex flex-col gap-2 sm:flex-row">
                      <strong>🏅 Unlock Task: 1,000 eANTC ⚡ </strong>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mt-4 text-sm text-gray-400">
                <strong>Disclaimer:</strong> The $500 USDT prize for the 1st place winner can be
                claimed on the Aptos network.
              </div>
            </div>
          </div>

          {/* Footer Section */}
          <div>
            <div className="my-4 border-t border-gray-700" />
            <Button
              variant="antc"
              disabled
              onClick={handleStartQuest}
              className="m-auto flex w-full !rounded-xl !bg-[#077d1e] px-4 py-2 font-mono !text-base font-bold text-white"
            >
              <p className="font-bold"> THE EVENT HAS CONCLUDED </p>
            </Button>
            {/* 
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-400">
                Message <span className="font-semibold text-blue-400">/antguardian</span> bot to get
                started, view live stats, and track progress.
              </p>
            </div> */}
          </div>
        </div>

        <SuccessModal
          isVisible={isModalSuccessOpen}
          onClose={() => setModalSuccessOpen(false)}
          reward={earnedPoints}
        />

        <AntModal
          walletAddress={account?.address}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          taskId={taskId}
          taskTitle={taskTitle}
          subTasks={subTasks}
          onCompleteTask={handleCompleteTask}
          // loading={loading}
          completedSubTasks={localCompletedSubTasks} // Pass the updated state here
          totalReferrals={0}
          totalReferralPoints={0}
          loading={false}
        />
      </div>
    );
  }

  return (
    <div className="card-shadow card-border min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="contest-card mb-2 font-tiny text-gray-100">{taskNumber}</div>
            <div className="contest-card mb-2 font-tiny text-gray-100">
              {/* <Tag className="rounded border border-indigo-500 font-tiny text-base" color="#f50">
                Social
              </Tag> */}

              {taskId === 1 && (
                <span className="bg-green-100 me-2 rounded border border-[#f50] px-2.5 py-0.5 font-tiny text-base text-[#f50]">
                  Social
                </span>
              )}
              {taskId === 6 && (
                <span className="bg-green-100 me-2 rounded border border-[#f50] px-2.5 py-0.5 font-tiny text-base text-[#f50]">
                  Social
                </span>
              )}

              {taskId === 2 && (
                <span className="bg-green-100 me-2 rounded border border-blue-300 px-2.5 py-0.5 font-tiny text-base text-blue-300">
                  Testnet
                </span>
              )}
              {taskId === 3 && (
                <span className="bg-green-100 me-2 rounded border border-yellow-500 px-2.5 py-0.5 font-tiny text-base text-yellow-500">
                  Mainnet
                </span>
              )}

              {taskId === 4 && (
                <span className="bg-green-100 me-2 rounded border border-[#f50] px-2.5 py-0.5 font-tiny text-base text-[#f50]">
                  Daily
                </span>
              )}

              {taskId === 5 && (
                <span className="bg-green-100 me-2 rounded border border-purple-500 px-2.5 py-0.5 font-tiny text-base text-purple-500">
                  Referral
                </span>
              )}
            </div>
          </div>
          <p className="text-lg font-semibold text-gray-100">{taskTitle}</p>
        </div>
        <div>
          <div className="border-antc -mx-4 mb-4"></div>

          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="bottom-card font-tiny text-gray-200">{taskPoints} EANTC ⚡ </div>
            <div
              className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
              onClick={handleStartQuest}
            >
              <p className="text-gray-300 duration-300 ease-in-out group-hover:text-white">
                {' '}
                START A QUEST
              </p>
              <ChevronsRight className="text-gray-300 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5 group-hover:text-white" />
            </div>
          </div>
        </div>
      </div>

      <SuccessModal
        isVisible={isModalSuccessOpen}
        onClose={() => setModalSuccessOpen(false)}
        reward={earnedPoints}
      />

      <AntModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        taskId={taskId}
        taskTitle={taskTitle}
        userId={userId}
        subTasks={subTasks}
        onCompleteTask={handleCompleteTask}
        totalReferrals={totalReferrals}
        totalReferralPoints={totalReferralPoints}
        loading={loading}
        completedSubTasks={localCompletedSubTasks} // Pass the updated state here
      />
    </div>
  );
};

export default TaskCard;
