'use client';

import { BaseProviders } from '@antcorefinance/ui';
import { AptosWalletAdapterProvider } from '@aptos-labs/wallet-adapter-react';
import { MartianWallet } from '@martianwallet/aptos-wallet-adapter';
import { MSafeWalletAdapter } from '@msafe/aptos-wallet-adapter';
import { PontemWallet } from '@pontem/wallet-adapter-plugin';
import { RiseWallet } from '@rise-wallet/wallet-adapter';
import { WalletProvider } from '@suiet/wallet-kit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Modal } from 'components/Modal/Modal';
import { chains } from 'config/chains';
import { FewchaWallet } from 'fewcha-plugin-wallet-adapter';
import { PetraWallet } from 'petra-plugin-wallet-adapter';

import { ThemeProvider } from 'components/theme-provider';
const wallets = [
  new PetraWallet(),
  new PontemWallet(),
  new FewchaWallet(),
  new MartianWallet(),
  new RiseWallet(),
  new MSafeWalletAdapter(Object.values(chains).map((chain) => chain.other.MSafeOrigin)),
];

export function Providers({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient();

  return (
    <>
      <ThemeProvider
        enableSystem={false}
        attribute="class"
        // enableColorScheme
        disableTransitionOnChange
        forcedTheme="dark"
      >
        <QueryClientProvider client={queryClient}>
          <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
            <WalletProvider>
              <BaseProviders>
                <Modal.Provider>{children}</Modal.Provider>
              </BaseProviders>
            </WalletProvider>
          </AptosWalletAdapterProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}
