'use client';
import Loading from '@/app/loading';
import { Button, SkeletonText } from '@antcorefinance/ui';
import { AntcIcon } from '@antcorefinance/ui/icons/AntcIcon';
import { AntcIconAnime } from '@antcorefinance/ui/icons/AntcIcon';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { message } from 'antd';
import { motion } from 'framer-motion';
import { CheckCircle2, Lock } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Countdown from './CountDown';
import { checkAndRegisterUser, checkRegistrationStatus, getCompletedTasks } from './events';
import FactionStats from './factionStats';
import { ProductFaq } from './faq';
import FactionJoin from './joinFaction';
import TaskCard from './taskCard';
// import Footer from './footer';

const tasks = [
  {
    id: 1,
    title: (
      <span>
        Follow Antcore on{' '}
        <svg
          width="22px"
          color="#fff"
          fill="#fff"
          style={{ display: 'inline', verticalAlign: 'sub' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
        >
          <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
        </svg>
      </span>
    ),
    totalPoints: 1000,
    number: '01',
    tasks: [
      {
        id: 1,
        title: (
          <span>
            1. Login and Follow Antcore on{' '}
            <svg
              width="22px"
              color="#fff"
              fill="#fff"
              style={{ display: 'inline', verticalAlign: 'sub' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
            >
              <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
            </svg>
          </span>
        ),
        points: 500,
      },
      {
        id: 2,
        title: '2. Like and Repost the Campaign Post',
        points: 500,
      },
    ],
  },
  {
    id: 2,
    title: 'Mint, Stake, and Prepare for the Mainnet',
    totalPoints: 2000,
    number: '02',
    tasks: [
      {
        id: 3,
        title: '1. Mint and Join the Antcore Hi-Colony',
        points: 500,
      },
      {
        id: 4,
        title: '2. Stake APT-ANTC LP Tokens',
        points: 1500,
      },
    ],
  },
  {
    id: 3,
    title: 'Allocate APT and Join Antcore Workers',
    points: 8001,
    number: '03',
    tasks: [
      {
        id: 5,
        title: 'Allocate APT and Join Antcore Workers',
        points: 8000,
      },
      {
        id: 6,
        title: 'Meet Antcore Workers ',
        points: 1,
      },
    ],
    countdown: 3600,
  },

  {
    id: 4,
    title: 'Antcore Daily',
    points: 300,
    totalPoints: 300,
    number: '05',
    tasks: [
      {
        id: 9,
        title: 'Visit Antore X page',
        points: 8000,
      },
      {
        id: 10,
        title: 'Visit Antcore Home Page ',
        points: 1,
      },
    ],
    countdown: 3600,
  },
  {
    id: 5,
    title: 'Building the Colony, One Ant at a Time',
    points: 8001,
    totalPoints: 'X',

    number: '06',
    tasks: [
      {
        id: 11,
        title: 'Refer A Friend',
        points: 8000,
      },
    ],
    countdown: 3600,
  },

  {
    id: 6,
    title: "Retweet Antcore's Updates",
    points: 1000,
    totalPoints: 1000,
    number: '04',
    tasks: [
      {
        id: 50,
        title: 'Retweet and Like the required tweet',
        points: 500,
      },
    ],
    countdown: 3600,
  },
];

export default function MainCard() {
  const [completedSubTasks, setCompletedSubTasks] = useState<number[]>([]);
  const { account, isLoading, connected } = useWallet();

  const [loading, setLoading] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);

  const [isClient, setIsClient] = useState(false);
  // const [message, setMessage] = useState<string | null>(null);

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchRegistrationStatus = async () => {
      if (account?.address) {
        setRegisterLoading(true);
        try {
          const status = await checkRegistrationStatus(account.address);
          setIsRegistered(status.isRegistered);
        } catch (error) {
          console.error('Error checking registration status:', error);
        } finally {
          setRegisterLoading(false);
        }
      }
    };

    fetchRegistrationStatus();
  }, [account?.address]);

  // Handle registration via form submission
  const handleRegister = async () => {
    if (!account?.address) {
      message.error('Please connect to a wallet.');
      return;
    }

    try {
      const response = await checkAndRegisterUser(account?.address);

      if (response.error) {
        message.error(response.error);
        return;
      }

      if (response.success || response.isRegistered) {
        setIsRegistered(true);

        if (response.isRegistered) {
          message.success('You are already registered for the event.');
        } else {
          message.success('You have successfully registered for the event.');
        }
      }
    } catch (error: any) {
      message.error('An error occurred during registration.');
    }
  };

  const toggleFAQ = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is the purpose of this testnet campaign?',
      answer:
        'The primary purpose of this testnet campaign is to rigorously test our platform ahead of the mainnet launch. By completing tasks, participants earn eANTC points, contributing to early ecosystem development and providing valuable insights to refine the platform',
    },

    {
      question: 'What are factions, and how do they work?',
      answer:
        'Factions are competitive groups within the campaign that allow participants to collaborate and compete for rewards. Users can switch factions every 8 hours, but joining the leading faction is restricted.',
    },
    {
      question: 'What are eANTC points, and how do they work?',
      answer:
        'eANTC points are testnet rewards that reflect your contributions during the campaign. They do not carry real value but allow us to track engagement and performance during the test phase.',
    },

    {
      question: 'When will participants receive their rewards?',
      answer:
        'Rewards will be distributed after the testnet campaign concludes and as we prepare for the mainnet launch. Specific timelines and details will be shared in upcoming announcements, so keep an eye on our official channels.',
    },

    {
      question: 'When does the campaign end?',
      answer:
        'The campaign will run until a specific date, allowing us to gather sufficient user activity and insights. The exact end date will be announced, so stay tuned for updates.',
    },
  ];

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      if (account?.address) {
        setLoading(true);
        try {
          const response = await getCompletedTasks(account.address);

          // Check if response contains an error
          if ('error' in response) {
            console.error('Error fetching completed tasks:', response.error);
            setCompletedSubTasks([]); // Clear the tasks in case of error
          } else {
            setCompletedSubTasks(response); // response should be number[] here
          }
        } catch (error: any) {
          console.error('Error fetching completed tasks:', error.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false); // Set loading to false if account.address is not available
        setRegisterLoading(false);
      }
    };

    // Clear completed tasks when account.address changes
    setCompletedSubTasks([]);

    fetchCompletedTasks();
  }, [account?.address]);

  if (!isClient) {
    return null;
  }

  const isPreviousTasksCompleted = (completedSubTasks: any) => {
    const requiredTaskIds = [1, 2, 3, 4];
    return requiredTaskIds.every((id) => completedSubTasks.includes(id));
  };

  const isXLoginCompleted = (completedSubTasks: any) => {
    const requiredTaskIds = [3];
    return requiredTaskIds.every((id) => completedSubTasks.includes(id));
  };

  const renderButton = () => {
    if (registerLoading) {
      return (
        <Button
          variant="antc"
          className="m-auto flex w-full cursor-not-allowed !rounded-xl !bg-[#077d1e] px-4 py-2 font-mono !text-base font-bold text-white"
        >
          <p className="font-bold"> Register Now </p>
          <Countdown />{' '}
        </Button>
      );
    }
    if (isRegistered) {
      return (
        <Button
          variant="antc"
          disabled
          className="m-auto flex w-full cursor-not-allowed !rounded-xl !bg-[#077d1e] px-4 py-2 font-mono !text-base font-bold text-white"
        >
          <p className="flex flex-row gap-2 font-bold">
            <CheckCircle2 color="#2ab246"> </CheckCircle2> Already Registered
          </p>
        </Button>
      );
    }
    return (
      <Button
        variant="antc"
        onClick={handleRegister}
        className="m-auto flex w-full !rounded-xl !bg-[#077d1e] px-4 py-2 font-mono !text-base font-bold text-white"
      >
        <p className="font-bold"> Register Now </p>
        <Countdown />
      </Button>
    );
  };

  return (
    <>
      <div style={{ background: '#010d12' }} className={`min-h-screen bg-[#010d12] text-white`}>
        <div className="m-auto w-full max-w-7xl pt-14">
          {/* <div className="flew-row m-auto mb-14 flex items-center justify-center gap-4">
            <AntcIcon width="20px" size="100px" className="w-[120]"></AntcIcon>

            <p className="items-center font-mono text-xl font-semibold italic"> TESTNET CAMPAIGN</p>
          </div> */}

          <header className="card-border relative mx-2 mb-16 flex min-h-[250px] max-w-screen-xl flex-col items-center justify-center overflow-hidden rounded-xl bg-cardBlack p-4 text-center shadow-2xl sm:min-h-[338px] sm:p-6 md:min-h-[200px]">
            {/* <h1 className="text-decorated text-2xl"> MAKE YOUR MOVE</h1> */}
            <div
              className="relative mx-auto max-w-6xl font-mono text-lg md:text-2xl"
              data-aos="fade"
              data-aos-delay="600"
            >
              <motion.p
                className={`mt-1 px-2 font-mono text-2xl font-bold uppercase sm:mt-1 sm:text-3xl`}
              >
                Complete tasks, earn rewards, and rise through the ranks.
              </motion.p>
            </div>

            {/* <span className="bg-green-500 flex items-center gap-2 rounded-full px-3 py-1 text-sm font-semibold text-gray-100">
              <div className="h-2.5 w-2.5 animate-pulse rounded-full bg-[#4df86e]"></div>
              LIVE
            </span> */}

            <p
              className="relatifve mx-auto mt-6 max-w-5xl px-2 font-mono text-sm sm:px-4 sm:text-base"
              data-aos="fade"
              data-aos-delay="600"
            >
              Complete tasks, earn rewards, and rise through the ranks. Join Antcore Workers and
              claim your share in the upcoming ANTC distribution. Every action counts—be part of the
              winning faction!
            </p>
          </header>

          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="mx-auto px-2">
            <FactionStats></FactionStats>
          </motion.div>

          {/* <section className="px-2 py-9 pb-4 text-center"> */}
          {/* <FactionJoin></FactionJoin> */}

          {/* <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mx-auto grid grid-cols-1 gap-8 sm:grid-cols-3"
          ></motion.div> */}
          {/* </section> */}
          <div className="mt-1 hidden justify-center px-5 text-center font-mono text-sm text-gray-200 sm:flex">
            Antcore&apos;s testnet campaign offers a gamified experience where participants engage
            with the platform, complete tasks, and earn eANTC points as rewards. This initiative
            provides valuable insights while giving participants a unique opportunity to earn a
            share in the upcoming ANTC distribution.
          </div>

          <div className="mt-6 hidden justify-center px-5 text-center font-mono text-sm font-bold text-gray-200 sm:flex">
            <div className="flex justify-center gap-4">
              <a
                href="https://aptos.dev/en/network/faucet"
                target="_blank"
                className="underline transition duration-100 hover:text-blue-600"
              >
                Testnet Faucet
              </a>
              <a
                href="/leaderboard"
                className="underline transition duration-100 hover:text-blue-600"
              >
                Faction Leaderboard
              </a>
            </div>
          </div>

          <div className={`min-h-screen bg-[#010d12]`}>
            <div className="font-roboto-mono m-auto max-w-7xl pl-2 pt-20 text-3xl font-bold text-gray-100 sm:pt-10">
              GETTING STARTED 🔗
            </div>

            <div className="m-auto flex w-full max-w-7xl flex-wrap justify-start gap-6 px-2 pt-10">
              {tasks.slice(0, 2).map((task) => (
                <TaskCard
                  key={task.id}
                  taskId={task.id}
                  taskTitle={task.title}
                  taskPoints={task.totalPoints}
                  subTasks={task.tasks}
                  completedSubTasks={completedSubTasks}
                  taskNumber={task.number}
                />
              ))}

              {isLoading || loading ? (
                <>
                  <div className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
                    <div className="flex h-full flex-col justify-between">
                      <div className="flex flex-col">
                        <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                          <SkeletonText />
                        </div>
                        <div className="mt-2 text-lg text-gray-100">
                          {!isLoading ? <SkeletonText /> : <SkeletonText />}
                        </div>
                      </div>
                      <div>
                        <div className="border-antc -mx-4 mb-4"></div>
                        <div className="mt-4 flex flex-row items-center justify-between">
                          <SkeletonText />
                          <div
                            className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
                            // onClick={handleStartQuest}
                          >
                            <SkeletonText />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : account?.address && completedSubTasks.length !== 0 ? (
                <>
                  {isXLoginCompleted(completedSubTasks) ? (
                    <TaskCard
                      key={tasks[2].id}
                      taskId={tasks[2].id}
                      taskTitle={tasks[2].title}
                      taskPoints={tasks[2].points}
                      subTasks={tasks[2].tasks}
                      completedSubTasks={completedSubTasks}
                      taskNumber={tasks[2].number}
                    />
                  ) : (
                    <div className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
                      <div className="flex h-full flex-col justify-between">
                        <div className="flex flex-col">
                          <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                            <SkeletonText />
                          </div>
                          <div className="mt-2 !max-w-[90%] text-lg text-gray-100">
                            <SkeletonText />
                          </div>
                        </div>
                        <div>
                          <div className="border-antc -mx-4 mb-4"></div>
                          <div className="mt-4 flex flex-row items-center justify-between">
                            <SkeletonText />
                            <div className="group flex cursor-pointer flex-row text-gray-100 hover:text-white">
                              <SkeletonText />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="absolute inset-0 z-10 flex items-center justify-center rounded-md bg-black bg-opacity-75">
                        <div className="flex flex-col items-center justify-center gap-4 font-bold text-white">
                          <Lock />
                          Complete previous tasks to unlock
                          {/* <div className="flex flex-row items-center gap-2">
                            Login Via
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 356 322"
                              fill="#fff"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M280.335 0H334.917L215.672 136.29L355.954 321.75H246.114L160.083 209.269L61.644 321.75H7.029L134.574 175.972L0 0H112.629L190.394 102.811L280.335 0ZM261.179 289.08H291.423L96.195 30.954H63.7395L261.179 289.08Z"
                                fill="var(--btn-txt-color)"
                              />
                            </svg>{' '}
                            to Unlock
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="card-shadow card-border relative min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
                    <div className="flex h-full flex-col justify-between">
                      <div className="flex flex-col">
                        <div className="contest-card mb-2 mt-2 !max-w-[40px] font-tiny text-gray-100">
                          <SkeletonText />
                        </div>
                        <div className="mt-2 !max-w-[90%] text-lg text-gray-100">
                          <SkeletonText />
                        </div>
                      </div>
                      <div>
                        <div className="border-antc -mx-4 mb-4"></div>
                        <div className="mt-4 flex flex-row items-center justify-between">
                          <SkeletonText />
                          <div className="group flex cursor-pointer flex-row text-gray-100 hover:text-white">
                            <SkeletonText />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="absolute inset-0 z-10 flex items-center justify-center rounded-md bg-black bg-opacity-75">
                      <div className="flex flex-col items-center justify-center gap-4 px-5 text-center font-bold text-white">
                        <Lock />
                        Complete previous tasks to unlock
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="font-roboto-mono m-auto max-w-7xl pl-2 pt-20 text-3xl font-bold text-gray-100">
              BONUS 💥
            </div>

            <div className="m-auto flex w-full max-w-7xl flex-wrap justify-start gap-6 px-2 pt-10">
              <TaskCard
                key={6}
                taskId={6}
                taskTitle={tasks[5].title}
                taskPoints={2000}
                subTasks={tasks[5].tasks}
                completedSubTasks={completedSubTasks}
                taskNumber={'04'}
              />

              {tasks.slice(3, 5).map((task) => (
                <TaskCard
                  key={task.id}
                  taskId={task.id}
                  taskTitle={task.title}
                  taskPoints={task.totalPoints}
                  subTasks={task.tasks}
                  completedSubTasks={completedSubTasks}
                  taskNumber={task.number}
                />
              ))}
            </div>

            {/* <div className="font-roboto-mono m-auto max-w-7xl pl-2 pt-20 text-3xl font-bold text-gray-100">
              ANTCORE EVENT ENGINE 🏁
            </div>

            <div className="m-auto flex w-full max-w-7xl flex-wrap justify-start gap-6 px-2 pt-10">
              <div className="card-border min-h-[330px] max-w-md rounded-md bg-cardBlack p-4">
                <div className="flex h-full flex-col justify-between">
                  <div className="flex h-full flex-col">
                    <div className="contest-card mb-2 font-mono text-gray-100"></div>
                    <div className="flex h-full flex-col justify-between text-lg text-gray-100">
                      <p className="max-w-[24rem] self-start font-semibold">
                        Antcore Event Engine powers faction events, delivering dynamic and
                        competitive events.
                      </p>
                      <div className="flex flex-col space-y-1">
                        <p className="text-center font-tiny text-4xl opacity-20"> 🎯 Coming soon</p>
                      </div>
                      <p className="mb-2 self-start"></p>
                    </div>
                  </div>
                  <div>
                    <div className="border-antc -mx-4 mb-4" />

                    <div className="flex flex-row items-center justify-between">
                      {renderButton()}
                    </div>
                  </div>
                </div>
              </div>

              <TaskCard
                key={99}
                taskId={99}
                taskTitle={'CTF'}
                taskPoints={'3000'}
                subTasks={[
                  {
                    id: 97,
                    title: 'Caesar Puzzle',
                    points: 3000,
                  },
                  {
                    id: 98,
                    title: 'Final Answer',
                    points: 8000,
                  },
                ]}
                completedSubTasks={completedSubTasks}
                taskNumber={'CTF'}
              />
            </div> */}

            <div className="font-roboto-mono m-auto max-w-7xl pl-2 pt-20 text-3xl font-bold text-gray-100">
              F.A.Q
            </div>

            <div className="mx-auto my-10 px-2">
              {faqs.map((faq, index) => (
                <div key={index} className="mb-4">
                  <button
                    className={`card-border w-full rounded-t-lg bg-cardBlack p-4 text-left ${
                      activeIndex === index ? 'rounded-b-none' : 'rounded-lg'
                    } focus:outline-none`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-bold">{faq.question}</span>
                      <span>{activeIndex === index ? '-' : '+'}</span>
                    </div>
                  </button>
                  {activeIndex === index && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: '100%' }}
                      transition={{ duration: 0.3 }}
                      className="card-border rounded-b-lg bg-cardBlack p-4"
                    >
                      <p>{faq.answer}</p>
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <footer className="justify-centerborder-t flex flex-col items-center border-[#141b2b] bg-cardBlack py-6 text-gray-300">
          <div className="flew-row m-auto flex items-center justify-center gap-3">
            <AntcIcon width="25px" className="w-[120]"></AntcIcon>
            <div className="flex flex-row gap-2">
              {' '}
              <p className="items-center font-mono text-xl font-semibold"> / Antcore Portal</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
